import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { fileAxios } from '../../../my-axios';
import UserContext, { getToken } from '../../../contexts/UserContext';
import ContentContext from '../../../contexts/ContentContext';
import { Button, Dropdown } from 'react-bootstrap';
import DataTable from '../../controls/DataTable';
import DataTablePagination from '../../controls/DataTablePagination';
import MsgBox from '../../controls/MsgBox';
import SearchBox from '../../controls/SearchBox';
import Spinner from '../../controls/Spinner';
import { getErrorDesc, date2str } from '../../../utils/common';


//https://www.geeksforgeeks.org/file-uploading-in-react-js/

//post to this url to upload file
const URL = "genieFile/file";
//const appointment_URL = "genieFile/appointmentFile";


export default function GenieFile() {
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);
  const contentCtx = useContext(ContentContext);
  const [file, setFile] = useState();
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    contentCtx.updatePath([{ title: 'Import GENIE Reports', link: '/genieFiles' }]);

  }, []);


  const onFileChange = (e) => {
    setFile(e.target.files);
    setMessage("");
    setSuccess(false);
  };


  const onFileUpload = () => {
    if (!file) {
      setMessage("Please choose files!");
      console.log("No file selected!");
      setSuccess(false);
      return;
    }
    setMessage("");
    var formData = new FormData();
    var myFiles = file;
    Object.keys(myFiles).forEach(key => {
      formData.append(myFiles.item(key).name, myFiles.item(key));
      //console.log(myFiles.item(key).name);
    });

    for (var key of formData.entries()) {
      console.log(key[0] + ', ' + key[1]);
    }
    // Update the formData object

    fileAxios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;

    fileAxios.post(URL, formData
    ).then((result) => {
      if (result.status === 200) {
        console.log("success");
        setMessage("Uploaded " + result.data.message);
        setSuccess(true);
      }

    }).catch((error) => {
      setMessage(error.response.data.message);
      console.log(error.response.data.message);
      setSuccess(false);
    });

  };


  return (
    <>
      <div className="row mb-6" >


        <div>
          {/* <h2>Select the type of XLS file here</h2>
          <h4>Select the file to upload!</h4> */}
          <label>Select the file to upload (*.xls)</label>

          <div>
            <input
              type="file"
              multiple
              onChange={onFileChange}
            />

            <Button onClick={onFileUpload}>
              Upload!
            </Button >

          </div>
          <div>
            {success ? (
              <i className="nav-icon fa fa-file" />
            ) : (
              <> </>
            )}
            {"     " + message}
          </div>
        </div>

      </div >
    </>
  );
}
