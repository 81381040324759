import React, { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import UserContext, { logout } from '../../../../contexts/UserContext';
import axios, { setLogin } from '../../../../my-axios'

export default function SignOut() {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);

    useEffect(() => {
        console.log("SignOut loading...");
        userCtx.logout();
        axios.post('/logout') //  /logout
            .then(response => {
                console.log('Logout');
            })
            .catch(error => {
                console.log("Logout error", error);
            });
        navigate("/signin");
    }, [])
    return (
        <div>SignOut</div>
    )
}
