import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { setToken } from '../../../../my-axios';
import UserContext, { getToken } from '../../../../contexts/UserContext';
import ContentContext from '../../../../contexts/ContentContext';
import DataTable from '../../../controls/DataTable';
import DataTablePagination from '../../../controls/DataTablePagination';
import SearchBox from '../../../controls/SearchBox';
import { Button, Dropdown } from 'react-bootstrap';
import MsgBox from '../../../controls/MsgBox';
import Spinner from '../../../controls/Spinner';
import { getErrorDesc, date2str } from '../../../../utils/common';


const API_URL = "/generalInformation/radiologies";
const URL = "/radiologies";
const titles = ["ID", "NAME", "CONTACT", "FAX", "ADDRESS", "WEBSITE"];
const cols = ["RadiologyId", "RadiologyName", "RadiologyContact", "RadiologyFax", "RadiologyAddress", "RadiologyWebsite"];

const statusList = [
    {
        code: -1,
        name: 'ALL'
    },
];

const initState = {
    data: [],
    selectedRow: null,
    loading: false,
    msg: null
};

const initPaginationState = {
    total: 0,
    page: 1,
    size: 10
};

const initFilterState = {
    text: '',
    selectedStatus: statusList[0]
};


export default function UserList() {
    //console.log('PatientList')
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const contentCtx = useContext(ContentContext);
    const [state, setState] = useState(initState);
    const [currentPage, setCurrentPage] = useState(initPaginationState);
    const [filter, setFilter] = useState(initFilterState);

    useEffect(() => {
        contentCtx.updatePath([{ title: 'Radiologies', link: '/radiologies' }]);
        loadData(filter);
    }, [currentPage.page, currentPage.size, filter.selectedStatus, filter.text]);

    const loadData = (filterValues) => {
        setState({ ...state, loading: true, msg: null });
        axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        axios.get(API_URL,
            {
                params: {
                    page: currentPage.page,
                    size: currentPage.size,
                    status: filterValues.selectedStatus.code,
                    text: filterValues.text
                },
            }).then((result) => {
                console.log("GetFromServer Users", result.data);
                setState({
                    ...state, data: formatData(result.data), loading: false, msg: null
                });
                setCurrentPage({ ...currentPage, total: result.data.total });
            }).catch((error) => {
                console.log('error:', error);
                if (error.response && error.response.status == 401) {
                    navigate("/signout");
                }
                else {
                    setState({
                        ...state, data: [], loading: false, msg: getErrorDesc(error)
                    });
                }
            });
    };



    const searchText = (text) => {
        console.log('searchText', text);
        setFilter({ ...filter, text });
    };

    const getStatus = (c) => {
        let result = statusList.find(status => status.code == c);
        if (result)
            return result;
        else {
            console.log('Status not found!');
            return { code: -1, name: 'Unknown' };
        }

    };

    const changeStatus = (selectedStatusCode) => {
        const selectedStatusItem = getStatus(selectedStatusCode);
        setFilter({
            ...filter,
            selectedStatus: selectedStatusItem
        });
    };

    const changeCurrentPage = (page) => {
        setCurrentPage({ ...currentPage, page });
    };
    const changePageSize = (size) => {
        setCurrentPage({ ...currentPage, size });
    };

    const formatData = (data) => {
        return data.map((r) => {
            return {
                ...r,
                status: getStatus(r.status).name,
            };
        });
    };

    const showDetails = (el) => {
        navigate(`${URL}/${el.RadiologyId}`);
    };

    const add = () => {
        navigate(`${URL}/new`);
    };

    return (
        <>
            <div className="row mb-4" >
                <div className="col text-left">
                    <SearchBox findText={searchText} />
                </div>
                <div className="col">
                    <div className="form-group">
                        <Dropdown onSelect={changeStatus}>
                            <Dropdown.Menu>
                                {
                                    statusList.map((c) => {
                                        return (<Dropdown.Item key={c.code} eventKey={c.code}>{c.name}</Dropdown.Item>);
                                    })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="col text-right">
                    <Button variant='success' onClick={add}>New</Button>
                </div>
            </div>

            <div className="row mb-6 P-10" >
                <div className="col-12">
                    <DataTable
                        module={URL}
                        id='id'
                        list={{
                            data: state.data,
                            columns: cols,
                            labels: titles
                        }}
                        view={{
                            label: 'DETAILS',
                            colSize: 1,
                            buttonSize: 12,
                            buttons: [
                                {
                                    label: 'Show',
                                    func: showDetails
                                }]
                        }}
                    />
                    <DataTablePagination page={currentPage.page} size={currentPage.size} total={currentPage.total} setPage={changeCurrentPage} setSize={changePageSize} />
                    <Spinner value={state.loading} />
                    {state.msg !== null && <MsgBox value={{ type: 'error', title: '', msg: state.msg }} />}
                </div>
            </div>
        </>
    );
}
