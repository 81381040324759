import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import axios, { setToken } from '../../../../my-axios';
import UserContext, { getToken } from '../../../../contexts/UserContext';
import ContentContext from '../../../../contexts/ContentContext';
import { Dropdown } from 'react-bootstrap';
import DataTable from '../../../controls/DataTable';
import DataTablePagination from '../../../controls/DataTablePagination';
import MsgBox from '../../../controls/MsgBox';
import SearchBox from '../../../controls/SearchBox';
import Spinner from '../../../controls/Spinner';
import { getErrorDesc, date2str, dateTime2str, getDate } from '../../../../utils/common';
import './styles.css';


const URL = "/appointment";
const titles = ["DATE", "SURNAME", "FIRSTNAME", "DURATION", "DETAIL", "DOCTOR", "STATUS"];
const cols = ["date", "surname", "firstname", "duration", "detail", "doctor_name", "status"];

const statusList = [
  {
    code: "ALL",
    name: 'All'
  },
  {
    code: 'CONFIRMED',
    name: 'CONFIRMED'
  },
  {
    code: "UNCONFIRMED",
    name: 'UNCONFIRMED'
  }
];

const initState = {
  data: [],
  selectedRow: null,
  loading: false,
  msg: null
};

const initPaginationState = {
  total: 0,
  page: 1,
  size: 10
};

const initFilterState = {
  text: '',
  date: getDate(),
  selectedStatus: statusList[0]
};


export default function AppointmentsList() {
  //console.log('PatientList')
  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();
  const userCtx = useContext(UserContext);
  const contentCtx = useContext(ContentContext);
  const [state, setState] = useState(initState);
  const [currentPage, setCurrentPage] = useState(initPaginationState);
  const [filter, setFilter] = useState({ selectedStatus: statusList[0], date: getDate(), text: search.get("text") });

  useEffect(() => {
    contentCtx.updatePath([{ title: 'Appointments', link: '/appointments' }]);
    loadData(filter);
  }, [currentPage.page, currentPage.size, filter.selectedStatus, filter.text, filter.date]);

  // useEffect(() => {
  //   var text = search.get("text");

  //   if (text) {
  //     console.log("Update filter in search: ", text);
  //     setFilter({ ...filter, text })
  //   }
  // }, [search])

  const loadData = (filterValues) => {
    setState({ ...state, loading: true, msg: null });
    axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
    axios.get(URL,
      {
        params: {
          page: currentPage.page,
          size: currentPage.size,
          status: filterValues.selectedStatus.code,
          text: filterValues.text,
          date: filterValues.date
        },
      }).then((result) => {
        console.log("GetFromServer!!!", result.data);
        setState({
          ...state, data: formatData(result.data.data), loading: false, msg: null
        });
        setCurrentPage({ ...currentPage, total: result.data.total });
      }).catch((error) => {
        console.log('error:', error);
        if (error.response && error.response.status == 401) {
          navigate("/signout");
        }
        else {
          setState({
            ...state, data: [], loading: false, msg: getErrorDesc(error)
          });
        }
      });
  };

  const searchText = (text) => {
    console.log('searchText', text);
    setFilter({ ...filter, text });
  };

  const getStatus = (c) => {
    let result = statusList.find(status => status.code == c);
    if (result)
      return result;
    else {
      console.log('Status not found!');
      return { code: -1, name: 'Unknown' };
    }

  };

  const changeStatus = (selectedStatusCode) => {
    const selectedStatusItem = getStatus(selectedStatusCode);
    setFilter({
      ...filter,
      selectedStatus: selectedStatusItem
    });
  };

  const changeDate = (e) => {
    var tmp = e.target.value;
    console.log("changeDate: ", tmp);
    setFilter({
      ...filter,
      date: tmp
    });
  };


  const changeCurrentPage = (page) => {
    setCurrentPage({ ...currentPage, page });
  };
  const changePageSize = (size) => {
    setCurrentPage({ ...currentPage, size });
  };

  const formatData = (data) => {
    return data.map((r) => {
      return {
        ...r,
        //status: getStatus(r.status).name,
        date: dateTime2str(r.date),
        date_create: date2str(r.date_create),
        date_change: date2str(r.date_change),
        note: r.note && r.note != 'null' ? r.note : ''
      };
    });
  };

  const showDetails = (el) => {
    navigate(`${URL}s/${el.id}`);
  };

  return (
    <>
      <div className="row mb-6">
        <div className="col-2 text-left">
          <form className="form-inline">
            <div class="form-group">
              <label htmlFor="repStartFrom" className="font-weight-normal" style={{ marginRight: '5px' }}>Date from:</label>
              <input id="repStartFrom" type="date" className="form-control" value={filter.date} onChange={changeDate} />
            </div>
          </form>
        </div>
        <div className="col text-left">
          <SearchBox findText={searchText} text={filter.text} />
        </div>
        <div className="col text-left">
          <div className="form-group">
            <Dropdown onSelect={changeStatus}>
              <Dropdown.Toggle id="basicItemCategory-basic">{filter.selectedStatus.name}</Dropdown.Toggle>
              <Dropdown.Menu>
                {statusList.map((c) => {
                  return (<Dropdown.Item key={c.code} eventKey={c.code}>{c.name}</Dropdown.Item>);
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

      </div>

      <div className="row mb-6 P-10" >
        <div className="col-12">
          <DataTable
            module={URL}
            id='id'
            list={{
              data: state.data,
              columns: cols,
              labels: titles
            }}
            view={{
              label: 'DETAILS',
              colSize: 1,
              buttonSize: 12,
              buttons: [
                {
                  label: 'Show',
                  func: showDetails
                }]
            }}
          />
          <DataTablePagination page={currentPage.page} size={currentPage.size} total={currentPage.total} setPage={changeCurrentPage} setSize={changePageSize} />
          <Spinner value={state.loading} />
          {state.msg !== null && <MsgBox value={{ type: 'error', title: '', msg: state.msg }} />}
        </div>
      </div>
    </>
  );
}
