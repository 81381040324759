import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import UserContext, { getToken } from '../../../../contexts/UserContext';
import ContentContext from '../../../../contexts/ContentContext';
import { Form, Dropdown, Button } from 'react-bootstrap';
import MsgBox from '../../../controls/MsgBox';
import Spinner from '../../../controls/Spinner';
import PopupPage from '../../../controls/PopupPage';
import { getErrorDesc, date2str } from '../../../../utils/common';
import axios, { fileAxios, setToken } from '../../../../my-axios';

const URL = '/resource/';
const API_URL = "/resource/";
const FILE_URL = "/resource/file/";

const initialState = {
    msg: null,
    loading: false,
    changing: false,
};
const initialUser = {
    id: null,
    link: '',
    pid: '',
    type: 'LINK',
    title: "",
    description: ""
};
const statusList = [
    {
        code: -1,
        name: 'LINK'
    },
    {
        code: 0,
        name: 'TEXT'
    },
    {
        code: 1,
        name: 'FILE'
    },
];

const code_dict = {
    'LINK': -1,
    'TEXT': 0,
    'FILE': 1
};
const initTypeState = {
    selectedStatus: statusList[0]
};


export default function UserDetail() {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const contentCtx = useContext(ContentContext);

    const { id } = useParams();
    const { pid } = useParams();
    const [state, setState] = useState(initialState);
    const [user, setUser] = useState(initialUser);
    const [oldUser, setOldUser] = useState(initialUser);
    const [showPopup, setShowPopup] = useState(false);
    const [newStatus, setNewStatus] = useState(0);
    const [saveConfirm, setSaveConfirm] = useState(false);
    const [removeConfirm, setRemoveConfirm] = useState(false);
    const [hasFile, setHasFile] = useState(false);
    const [type, setType] = useState(initTypeState);

    const [file, setFile] = useState();
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(false);

    const description_max_length = 255;

    const onFileChange = (e) => {
        setFile(e.target.files);
        //to code
        changeStatus(1);
        setMessage("");
        setSuccess(false);
    };


    const isURL = (str) => {
        var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        return regexp.test(str);
    };

    const FileHandler = () => {
        if (!file) {
            setMessage("Please choose files!");
            console.log("No file selected!");
            setSuccess(false);

            if (user.id) {
                var formData = new FormData();
                formData.append("type", "FILE");
                formData.append("title", user.title);
                formData.append("description", user.description);
                formData.append("pid", user.pid);
                formData.append("id", id);
                axios.put(`${API_URL}${id}`, formData
                ).then((result) => {
                    if (result.status === 200) {
                        console.log("success");
                        setMessage("Uploaded " + result.data.message);
                        setSuccess(true);
                        setState({ ...state, loading: false, msg: null, changing: false });
                        navigate("/resources/patient/" + user.pid);
                        return true;
                    }

                }).catch((error) => {
                    console.log('UpdateUser error:', error);
                    if (error.response && error.response.status == 401) {
                        navigate("/signout");
                    }
                    else {

                        setState({
                            ...state,
                            loading: false, msg: getErrorDesc(error)
                        });
                    }
                    setMessage(error.response.data.message);
                    console.log(error.response.data.message);
                    setSuccess(false);
                });
            }


            return;
        }
        setMessage("");

        console.log(id, ": id");
        var formData = new FormData();
        formData.append("link", "");
        formData.append("type", "FILE");
        formData.append("title", user.title);
        if (user.id) {
            formData.append("pid", user.pid);
        }
        else {
            formData.append("pid", pid);
        }
        formData.append("description", user.description);


        var myFiles = file;
        Object.keys(myFiles).forEach(key => {
            formData.append(myFiles.item(key).name, myFiles.item(key));
        });

        for (var key of formData.entries()) {
            console.log(key[0] + ', ' + key[1]);
        }
        // Update the formData object

        fileAxios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;

        if (!user.id) {
            fileAxios.post(`${FILE_URL}`, formData
            ).then((result) => {
                if (result.status === 200) {
                    console.log("success");
                    setMessage("Uploaded " + result.data.message);
                    setSuccess(true);
                    console.log("Insert User: ", result.data);
                    loadData(result.data.data);
                    setState({ ...state, loading: false, msg: null, changing: false });
                    navigate("/resources/patient/" + pid);
                    return true;
                }

            }).catch((error) => {
                console.log('UpdateUser error:', error);
                if (error.response && error.response.status == 401) {
                    navigate("/signout");
                }
                else {

                    setState({
                        ...state,
                        loading: false, msg: getErrorDesc(error)
                    });
                }
                setMessage(error.response.data.message);
                console.log(error.response.data.message);
                setSuccess(false);
                return false;
            });

        }
        else {
            fileAxios.put(`${FILE_URL}${id}`, formData
            ).then((result) => {
                if (result.status === 200) {
                    console.log("success");
                    setMessage("Uploaded " + result.data.message);
                    setSuccess(true);
                    setState({ ...state, loading: false, msg: null, changing: false });
                    navigate("/resources/patient/" + user.pid);
                    return true;
                }

            }).catch((error) => {
                console.log('UpdateUser error:', error);
                if (error.response && error.response.status == 401) {
                    navigate("/signout");
                }
                else {

                    setState({
                        ...state,
                        loading: false, msg: getErrorDesc(error)
                    });
                }
                setMessage(error.response.data.message);
                console.log(error.response.data.message);
                setSuccess(false);
            });
        }
        return false;
    };


    useEffect(() => {
        contentCtx.updatePath([
            {
                title: 'Resources',
                link: '/resources'
            },

            {
                title: 'Resources for patient',
                link: `/resources/patient/${user.pid}`
            }]);
        if (id)
            loadData(id);
    }, [id]);

    const loadData = (id) => {
        setState({ ...state, loading: true, msg: null });
        axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        axios.get(`${API_URL}/${id}`).then((result) => {
            console.log("GetFromServer", result.data);

            contentCtx.updatePath([
                {
                    title: 'Resources',
                    link: '/resources'
                },

                {
                    title: 'Resources for patient',
                    link: `/resources/patient/${result.data.pid}`
                },
            ]);
            changeStatus(code_dict[result.data.type]);
            if (result.data.type === "FILE") {
                setHasFile(true);
            }
            setUser({ ...result.data });
            setOldUser({ ...result.data });
            setState({ ...state, loading: false, msg: null, changing: false });


        }).catch((error) => {

            console.log('error:', error);
            if (error.response && error.response.status == 401) {
                navigate("/signout");
            }
            else {
                setUser(initialUser);
                setState({
                    ...state,
                    loading: false, msg: getErrorDesc(error)
                });
            }
        });
    };

    const changeValue = (field, value) => {
        console.log("changeValue of " + field, value);
        if (field == "description") {
            //255 is the maxium length from in DB
            if (value.length > description_max_length) {
                value = value.substring(0, description_max_length);
            }
        }
        setState({ ...state, msg: null, changing: true });
        setUser({ ...user, [field]: value });
    };


    const save = () => {
        setShowPopup(true);
        setState({ ...state, loading: true, msg: null });

        //link is not a valid url
        if (type.selectedStatus.name === "LINK") {
            if (!isURL(user.link)) {
                setState({ ...state, loading: false, msg: "The link is not a valid url, please check if the link starts with http/https://" });
                return;
            }
        }


        axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        if (user && user.id && type.selectedStatus.name == "FILE") {
            console.log("update file");
            const rep = FileHandler();
            if (rep) {
                setState({ ...state, loading: false, msg: null, changing: false });
                navigate("/resources/patient/" + user.pid);
            }

        }
        else if (!user.id && type.selectedStatus.name == "FILE") {
            console.log("upload file");
            const rep = FileHandler();
            if (rep) {
                setState({ ...state, loading: false, msg: null, changing: false });
                navigate("/resources/patient/" + id);
            }

        }

        else if (user && user.id) {
            // update
            axios.put(`${API_URL}${user.id}`, user).then((result) => {
                console.log("Update user: ", result.data);
                loadData(user.id);
                setState({ ...state, loading: false, msg: null, changing: false });
                navigate("/resources/patient/" + user.pid);
            }).catch((error) => {
                console.log('UpdateUser error:', error);
                if (error.response && error.response.status == 401) {
                    navigate("/signout");
                }
                else {

                    setState({
                        ...state,
                        loading: false, msg: getErrorDesc(error)
                    });
                }
            });
        }
        else {
            // insert

            user.type = type.selectedStatus.name;
            user.pid = pid;

            axios.post(URL, user).then((result) => {
                console.log("Insert User: ", result.data);
                loadData(result.data.data);
                setState({ ...state, loading: false, msg: null, changing: false });
                navigate("/resources/patient/" + pid);
            }).catch((error) => {
                console.log('InsertUser error:', error);
                if (error.response && error.response.status == 401) {
                    navigate("/signout");
                }
                else {
                    setState({
                        ...state,
                        loading: false, msg: getErrorDesc(error)
                    });
                }
            });
        }
        setState({
            ...state,
            loading: false
        });
    };

    const remove = () => {
        setRemoveConfirm(false);
        setState({ ...state, loading: true, msg: null });
        axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        if (user && user.id) {
            console.log('delete id', user.id);
            // delete
            axios.delete(`${URL}/${user.id}`).then((result) => {
                console.log("Delete resource: ", result.data);
                setState({ ...state, loading: false, msg: null });
                navigate("/resources/patient/" + user.pid);
            }).catch((error) => {
                console.log('DeleteUser error:', error);
                if (error.response && error.response.status == 401) {
                    navigate("/signout");
                }
                else {
                    setState({
                        ...state,
                        loading: false, msg: getErrorDesc(error)
                    });
                }
            });
        }
    };

    const sendNotification = () => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        if (user && user.id) {
            console.log('Manual noti', user.id);
            // delete
            axios.put(`/resource/manualNotification/${user.id}`).then((result) => {
                console.log("Manual not resource: ", result.data);
                alert("Notification Sent");
                setState({ ...state, loading: false, msg: null });

            }).catch((error) => {
                console.log('manual not error:', error);
                if (error.response && error.response.status == 401) {
                    navigate("/signout");
                }
                else {
                    setState({
                        ...state,
                        loading: false, msg: getErrorDesc(error)
                    });
                }
            });
        }
    };

    const getStatus = (c) => {
        let result = statusList.find(status => status.code == c);
        if (result)
            return result;
        else {
            console.log('Status not found!');
            return { code: -1, name: 'LINK' };
        }

    };

    const changeStatus = (selectedStatusCode) => {
        const selectedStatusItem = getStatus(selectedStatusCode);
        user.type = selectedStatusItem.name;
        setType({
            ...type,
            selectedStatus: selectedStatusItem
        });
        console.log(user.type);
    };

    const downloadFile = () => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${userCtx.getToken()}`;
        if (user && user.id) {
            console.log('downald file', user.id);
            axios.get(`${"/resource/file"}/${user.id}`, {
                responseType: "blob",
            }).then((response) => {
                console.log("Downald File: ", response.data);
                const pdfBlob = new Blob([response.data], { type: "application/pdf" });
                const url = window.URL.createObjectURL(pdfBlob);
                const tempLink = document.createElement("a");
                tempLink.href = url;
                console.log(user.link);

                const f_name = user.link.split("/").pop();
                tempLink.setAttribute(
                    "download",
                    `${f_name}`
                ); // Set the desired filename for the downloaded file

                // Append the <a> element to the body and click it to trigger the download
                document.body.appendChild(tempLink);
                tempLink.click();

                // Clean up the temporary elements and URL
                document.body.removeChild(tempLink);
                window.URL.revokeObjectURL(url);
            }).catch((error) => {
                console.log('DeleteUser error:', error);
                if (error.response && error.response.status == 401) {
                    navigate("/signout");
                }
                else {
                    setState({
                        ...state,
                        loading: false, msg: getErrorDesc(error)
                    });
                }
            });
        }
        console.log(hasFile);
    };

    const showRemove = () => {
        setRemoveConfirm(true);
        setState({ ...state, changing: false });
    };

    const add = () => {
        setUser(initialUser);
        setState({ ...state, changing: false });
    };

    const cancel = () => {
        console.log("cancel");
        setState({ ...state, changing: false });
        setUser(oldUser);
    };
    return (

        <div>
            <div className="card-body">
                <Spinner value={state.loading} />

                <form role="form " >
                    <div className="row">
                        <div className="col">
                            <MsgBox value={{ type: 'error', title: '', msg: state.msg }} />
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col mr-3">
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repName" className="font-weight-normal">Title</label>
                                        <input id="repName" type="text" className="form-control" value={user.title} onChange={(e) => changeValue('title', e.target.value)} />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col mr-3">

                            <div className="row">
                                <div className="col">
                                    <label htmlFor="repEmailt" className="font-weight-normal">Type</label>
                                    <Dropdown onSelect={changeStatus}>
                                        <Dropdown.Toggle id="basicItemCategory-basic" >
                                            {type.selectedStatus.name}
                                        </Dropdown.Toggle >
                                        <Dropdown.Menu>
                                            {
                                                statusList.map((c) => {
                                                    return (<Dropdown.Item key={c.code} eventKey={c.code}>{c.name}</Dropdown.Item>);
                                                })
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </div>


                            </div>
                        </div>

                    </div>
                    <div className="row ">
                        <div className="col ">
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="repEmailt" className="font-weight-normal">Description</label> <>({user.description.length}/{description_max_length})</>
                                        <textarea id="repEmail" type="text" className="form-control" value={user.description} rows={3} onChange={(e) => changeValue('description', e.target.value)} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {type.selectedStatus.name == "LINK" || (type.selectedStatus.name == "FILE" && !state.changing) ?
                        <div className="row ">
                            <div className="col mr-3">

                                <div className="form-group ">
                                    <label htmlFor="repName" className="font-weight-normal">Link</label>
                                    <input id="repName" type="text" className="form-control" value={user.link} onChange={(e) => changeValue('link', e.target.value)} />

                                </div>
                            </div>
                        </div> : <></>} <></>


                    {type.selectedStatus.name === "FILE" ? <div className="row mb-6" >
                        <div className='col'>
                            {/* <h2>Select the type of XLS file here</h2>
          <h4>Select the file to upload!</h4> */}
                            <label style={{ fontWeight: 'normal' }}>Select the file to upload (*.pdf)</label>

                            <div>
                                <input
                                    type="file"

                                    onChange={onFileChange}
                                />

                            </div>

                            <div>
                                {success ? (
                                    <i className="nav-icon fa fa-file" />
                                ) : (
                                    <> </>
                                )}
                                {"     " + message}
                            </div>
                        </div>

                    </div > : <></>} <></>

                    <div className="row">

                        <div className="col text-right">
                            {!state.changing ? <Button variant="secondary" onClick={sendNotification}> Send Notification Manually</Button> : <></>} <></>
                            {hasFile && state.changing === false ? <Button style={{ width: '100px' }} variant="secondary" onClick={downloadFile}> Download</Button> : <></>} <></>
                            {state.changing ? <Button variant="secondary" onClick={cancel}> Cancel</Button> : <></>} <></>
                            {user && user.id && state.changing === false ? <Button variant="secondary" onClick={showRemove}> Remove</Button> : <></>} <></>

                            <Button style={{ width: '100px' }} onClick={save}> Save</Button>
                        </div>
                    </div>
                    <div className='row'>
                        <PopupPage
                            size="md"
                            openDialog={saveConfirm}
                            setOpenDialog={setSaveConfirm}
                            title="Resource"
                            label='Yes'
                            onSave={add}
                            labelCancel='No'>
                            <p> Resource has been successfully saved! </p><br />
                        </PopupPage>
                        <PopupPage
                            size="md"
                            openDialog={removeConfirm}
                            setOpenDialog={setRemoveConfirm}
                            title="Remove resource"
                            label='Yes'
                            onSave={remove}
                            labelCancel='No'>
                            <p> Are you sure to remove this resource? </p>
                        </PopupPage>
                    </div>
                </form>
            </div >
        </div >
    );
}
