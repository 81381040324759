import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';
import MainPage from './components/layout/MainPage';
import SignOut from './components/features/Auth/SignOut';
import Privacy from './components/resources/Privacy';
import Contact from './components/resources/Contact';
import AuthPage from './components/features/Auth/AuthPage';

export default function App() {


  return (
      <div className="wrapper">
      <Routes>
        {/* <Route path="/" element={<Navigate to="/home.html" />} /> */}
        {/* <Route path="/information" element={<Navigate to="/information.html" />} /> */}
        {/* <Route path="/contact" element={<Navigate to="/contact.html" />} /> */}
        
        <Route path="/signin" element={<AuthPage />} />
        <Route path="/signout" element={<SignOut />} />
        <Route path="/forgot-password" element={<AuthPage comp='ForgetPassword' />} />
        <Route path="/reset-password/:token" element={<AuthPage comp='ResetPassword' />} />

        <Route path="/terms" element={<Privacy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/*" element={<MainPage />} />
        {/* <Route path="/" element={<MainPage />} /> */}
      </Routes>
    </div>
  );
}
